$sceneG: 8px;
$color: black;
.main-scene {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &-background {
    background: white;

    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    > div {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &-lines {
      position: absolute;
      height: 1.5*$sceneG;
      width: 300%;
      transform: translateX(-33%);
      display: flex;

      > div {
        height: 100%;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          width: 30%;
          height: 30%;
          background: black;

        }
      }

      &-chess {

        &-black {
          background: black;
        }

        &-white {
          background: white;
        }
      }

      &-top {
        top: 0;
      }

      &-bottom {
        bottom: 0;
      }

      &-scroll {
        transition: 300ms;
        transform: translateX(0);
      }
    }
  }


  &-topbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    position: relative;

    &-menu {
      position: absolute;
      top: 100%;

      border: 1px solid black;
      z-index: 10;
      background: white;

      transition: 0.3s linear;
      transform: translateX(-100%);

      &.open {
        transition: 0.3s linear;
        transform: translateX(0);
      }

      > ul {
        > li {
          padding: 0.5rem;
          border-bottom: 1px solid black;
        }

        > li:last-child {
          border-bottom: none;
        }
      }
    }

    &-menu-toggler {
      width: 3rem;
      aspect-ratio: 1/1;
      border: 1px solid black;

      img {
        width: 100%;
      }
    }

    &-collection-name {
      width: 100%;
      text-align: center;
    }
  }

  &-main {
    display: flex;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;

    &-loading {
      position: absolute;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 10;
      &-active {
        display: block;
        >div {

          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-inactive {
        display: none;
      }
    }

    &-background {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: height 100ms;


      > div {
        position: relative;
        width: 100%;
        height: 100%;
      }

      &-elements {
        position: absolute;
        width: 100%;
        height: 200%;

        > div {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 0 $sceneG;

          > div {
            width: 100%;
            height: 100%;
            border: 1px solid black;
          }
        }

      }

      &-top {
        position: relative;
        top: 0;
        width: 100%;
        height: 50%;
      }

      &-bottom {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 50%;

        > div {
          transform: translateY(-50%);
        }
      }
    }
  }

  .lines {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  &-bottom {
    display: flex;
    align-items: flex-end;
  }

}
