@import "./MainScene.scss";

.nft-swiper-container {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.nft-swiper {
    width: 100%;
    height: 100%;
}

.nft-swiper-slide {
    align-items: center;
    justify-content: center;
    display: flex;
}

.nft-swiper-slide.swiper-slide {
    opacity: 0;
}

.nft-swiper-slide.swiper-slide-visible {
    opacity: 1;
    transition: 0.2s ease-in-out;
}


.nft-swiper-slide > .swiper-slide-shadow {
    display: none
}