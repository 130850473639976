.profile-explanation {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &-frame {
    border: 1px solid black;

    &-header {
      padding: 0.5rem;
      text-align: center;
    }

    &-body {
      padding: 0.5rem;
    }
    &-call-to-action {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }
  }
}