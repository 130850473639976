.uploadcare--widget {
  width: 100%;
  height: 100%;

  &__button {
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__progress {
    opacity: 0;
    display: none;
  }

  &__text {
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &__file-name {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__file-size {
    display: none;
  }
}

.uploadcare--dialog{
  .uploadcare--menu__toggle {
    display: none;
  }
  .uploadcare--menu__items {
    display: none;
  }
  .uploadcare--powered-by {
    display: none;
  }
  .uploadcare--file-sources {
    display: none;
  }
  .uploadcare--button_primary {
    background: black;
    font-family: "Brutal Pixels", monospace;
    text-transform: uppercase;
    font-size: 1rem;
    border-radius: 0;
    border: none;
  }
  .uploadcare--footer__button {
    background: black;
    font-family: "Brutal Pixels", monospace;
    text-transform: uppercase;
    font-size: 1rem;
    border-radius: 0;
    border: none;
    color: white;
  }
}