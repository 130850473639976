.collection {
  width: 100%;
  height: 100%;
  display: block;

  &-name {
    text-align: center;
    object-fit: contain;
  }

  &-scroller {
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
}