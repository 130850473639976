@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Druk Wide Bold Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Druk Wide Bold Bold'), url('/public/font/DrukWideBold.woff') format('woff');
}

@font-face {
    font-family: 'Brutal Pixels';
    font-style: normal;
    font-weight: normal;
    src: local('Brutal Pixels'), url('/public/font/brutal-pixels.woff2') format('woff2');
}

@font-face {
    font-family: 'Tusker Grotesk';
    src: local('Tusker Grotesk 9800 Super'), local('Tusker-Grotesk-9800-Super'),
    url('/public/font/TuskerGrotesk-9800Super.woff2') format('woff2'),
    url('/public/font/TuskerGrotesk-9800Super.woff') format('woff'),
    url('/public/font/TuskerGrotesk-9800Super.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    /*background: black;*/
}

:root {
    --tg-theme-bg-color: #fff;
    --tg-theme-text-color: black;
    --tg-theme-hint-color: #929292;
    --tg-theme-link-color: #e420c3;
    --tg-theme-button-color: #5bc8fb;
    --tg-theme-button-text-color: black;
    --tg-theme-secondary-bg-color: #1c1c1c;
    --default-font: 'Brutal Pixels', 'Druk Wide Bold Bold', 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');

@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@keyframes move-clouds-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 10000px 0;
    }
}

:root {
    --max-width: 1100px;
    --border-radius: 12px;
    --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    color: black;
    background-size: cover;
    width: 100dvw;
    height: 100dvh;
    font-family: 'Brutal Pixels', 'Druk Wide Bold Bold', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    text-transform: uppercase;
}


body > div {
    width: 100%;
    height: 100%;
}

body > div > div {
    width: 100%;
    height: 100%;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.25rem;
}

h4 {
    font-size: 1.25rem;
}

.inverted {
    color: white;
    background: black;
}

.inverted .inverted {
    color: black;
    background: white;
}

tc-root * {
    font-family: "Brutal Pixels", monospace !important;
    text-transform: uppercase;
}

button[data-tc-icon-button="true"] {
    display: none;
}

h2[data-tc-h2="true"] {
    display: none;
}

h1[data-tc-h1="true"] {
    display: none;
}

[data-tc-wallets-modal-universal-mobile="true"] ul {
    display: none;
}

[data-tc-wallets-modal-container="true"] > div {
    background: white;
}

[data-tc-wallets-modal-container="true"] > div > div {
    min-height: 0;
    background: white;
    padding: 1rem;
}

[data-tc-button="true"] {
    margin: 0 !important;
    width: 100% !important;
    background-color: black !important;
    font-family: "Brutal Pixels", monospace !important;
    color: white !important;

}

[data-tc-wallets-modal-container="true"] > div > div:last-child {
    display: none;
}

input {
    text-transform: uppercase;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}