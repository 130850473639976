@import "MainScene";

.buy-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.buy-button {
  background: $color;
  padding: 0.75rem 0.75rem 0.75rem 1.5rem;
  color: black;

  &-vertical-border {
    border-left: 1px solid black;
    display: inline-block;
    margin: -0.75rem 0.75rem -1.5rem 0.75rem;
    height: calc(100% + 1.5rem);
  }

  img {
    display: inline-block;
    height: 1.5lh;
  }

  &-with-ton {
  }
}
.disable {
  display: none;
}