@import "MainScene";

.nft-card {
  max-width: 100%;
  padding: $sceneG;

  &-frame {
    position: relative;
    max-width: 100%;
    aspect-ratio: 1/1;
    width: 100%;
    background: black;

    &-corner-left-top-landscape-line {
      position: absolute;
      left: -$sceneG;
      top: -$sceneG;
      height: $sceneG;
      width: 5*$sceneG;
      background: $color;
    }

    &-corner-left-top-portrait-line {
      position: absolute;
      left: -$sceneG;
      height: 50%;
      width: $sceneG;
      background: $color;
    }

    &-corner-right-top-landscape-line {
      position: absolute;
      right: -$sceneG;
      top: -$sceneG;
      height: $sceneG;
      width: 5*$sceneG;
      background: $color;
    }

    &-corner-right-top-portrait-line {
      position: absolute;
      right: -$sceneG;
      height: 4*$sceneG;
      width: $sceneG;
      background: $color;
    }

    &-corner-right-bottom-portrait-line {
      position: absolute;
      bottom: 0;
      right: -$sceneG;
      height: 4*$sceneG;
      width: $sceneG;
      background: $color;
    }


    &-corner-right-lines {
      position: absolute;
      right: -2*$sceneG;
      width: 2*$sceneG;
      top: calc(50% + 2 * $sceneG);
      height: 10*$sceneG;

      > img {
        object-fit: cover;
      }
    }

    &-corner {
      position: absolute;
      right: 1rem;
      bottom: -2rem;
      top: 100%;
      padding: 0.5rem;

      &-author {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: right;
      }

      &-author-instagram {
        margin-top: 2px;
      }

      &-author-instagram-tag {
        top: 3px;
        margin: 0 0.5rem 1px 0.5rem;
        font-style: italic;

        & .typed-cursor {
          display: none;
        }
      }
    }

    &-braillie-image {
      line-height: 1.4rem;
      position: relative;
      &-text {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 2;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      padding: 1rem;


      &-image {
        background: white;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;
        filter: grayscale(100%);
      }
      &-video {
        aspect-ratio: 1;
        object-fit: cover;
        padding: 0.5rem;
        background-color: white;
      }

      &-image-background-blur {
        width: 100%;
        z-index: -1;
        position: absolute;

        > div {
          width: 100%;
          height: 100%;
          position: relative;

          > div {
            box-shadow: inset 0 0 8px 14px white, inset 0 0 0 10px white;
            width: 100%;
            height: 100%;
            z-index: 0;
          }

          > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
          }
        }

      }
    }


    &-save {
      position: absolute;
      bottom: -1.5rem;
      right: 1rem;

      &-button {
        padding: 0.25rem 1rem;

      }
    }
  }

  &-name {
    color: white;
    font-size: 20px;
    text-align: center;
    position: relative;
    word-wrap: break-word;
  }

}
