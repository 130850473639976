.profile-edit {
  width: 100%;
  height: 100%;
  padding: 1rem 0;

  &-wallet {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
  }

  &-editables {
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  &-form-field:first-child {
    margin-top: 0;
  }
  &-form-field:last-child {
    margin-bottom: 0;
  }

  &-form-field {
    display: flex;
    border: 1px solid black;
    height: 2rem;
    margin: 1rem 0 0 0;

    &-icon {
      background: black;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;

      > svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    &-value {
      flex-grow: 1;
      width: 100%;

      input {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
      }
    }

    &-button {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      background: black;
    }
  }

  &-nfts {
    flex-grow: 1;
    height: 100%;
  }
}