.add-nft {
  width: 100%;
  aspect-ratio: 1/1.2;

  &-with-image {
    aspect-ratio: initial;
    position: relative;

    .add-nft-background {

    }
  }

  &-background {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    padding: 1rem;

    &-image {
      border: 1px solid black;
      padding: 0.5rem;
      background: white;
    }

    &-image-background-blur {
      background-color: white;
      width: 100%;
      height: 100%;
      z-index: -1;
      position: absolute;

      > div {
        width: 100%;
        height: 100%;
        position: relative;

        > div {
          box-shadow: inset 0 0 8px 14px white, inset 0 0 0 10px white;
          width: 100%;
          height: 100%;
          z-index: 0;
        }

        > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }

    }
    &-save {
      position: absolute;
      bottom: -1.5rem;
      right: 1rem;
      &-button {
        padding: 0.25rem 1rem;

      }
    }
  }
  &-ontop {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &-sign {
    display: block;
    font-size: 5rem;
  }

  > input {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}