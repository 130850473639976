.ton-connect-button {
  button {
    border-radius: 0;
    background: black;
    height: initial !important;
    padding: 1rem;
    div {
      text-transform: uppercase;
      font-size: 1.25rem;
      font-family: "Brutal Pixels", monospace;
      line-height: 1.5;
    }
  }
  [data-tc-text="true"] {
    color: white;
  }
  &-s {
    button {
      padding: 0.5rem;
      div {
        font-size: 1rem;
        line-height: 1;
      }
    }
  }
}
[data-tc-dropdown-container="true"] {
  font-size: 1rem;
}